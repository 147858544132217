import React, {useEffect, useState} from "react";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import history from "../../helper/browserHistory"
import userStore from "../../components/adminComponents/userManagement/userStore";
import {withTranslation} from "react-i18next";
import {backup, jumpToTop} from "../../helper/util";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";

function register(language) {
    history.push(`/${language}/register`)
}

function login(setLoginError, user, loginError, i18n, setErrorMessage) {
    setLoginError(prev => ({...prev, logIn: false}));
    if (user.username === "") {
        setErrorMessage(i18n.t('login:alert.empty_username'));
        setLoginError(prev => ({...prev, username: true}));
    } else if (user.password === "") {
        setErrorMessage(i18n.t('login:alert.empty_password'));
        setLoginError(prev => ({...prev, password: true}));
    } else {
        userStore.authenticateUser(user).then((res) => {
            if (res === "Username or password is incorrect") {
                setErrorMessage(res);
                setLoginError(prev => ({...prev, logIn: true}));
            } else {
                backup();
                history.push("/" + i18n.language.replace("_", "-") + "/")
            }
        })

    }
}

function handleChange(prop, e, setLoginError, setUser) {
    let value = e.target.value;
    setLoginError(prev => ({...prev, username: false, password: false}));
    setUser(prev => ({...prev, [prop]: value}))

}

function onKeyDown(event, i18n, setLoginError, user, loginError, setErrorMessage) {
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        login(setLoginError, user, loginError, i18n, setErrorMessage)
    }
}

function showErrorMessage(message, i18n) {
    return <Message
        error
        header={i18n.t('login:alert.login_failed')}
        content={message}
    />
}

const LoginPage = (props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [user, setUser] = useState({
        username: '',
        password: ''
    })
    const [loginError, setLoginError] = useState({
        username: false,
        password: false,
        logIn: false
    })

    useEffect(() => {
        jumpToTop(window);
    }, []);

    const {i18n} = props;
    return (
        <Grid>
            <Grid.Row centered>
                <Grid.Column computer={5} tablet={8} mobile={12} style={{paddingTop: 50}}>
                    <Form
                        error={loginError.logIn || loginError.username || loginError.password}>
                        <Form.Field error={loginError.username}>
                            <label className={"header-primary"}>{i18n.t("login:user.username")}</label>
                            <input
                                onKeyPress={(e) => onKeyDown(e, i18n, setLoginError, user, loginError, setErrorMessage)}
                                value={user.username}
                                onChange={(e) => handleChange("username", e, setLoginError, setUser)}
                                type='text'/>

                        </Form.Field>
                        <Form.Field error={loginError.password}>
                            <label className={"header-primary"}>{i18n.t("login:user.password")}</label>
                            <input
                                onKeyPress={(e) => onKeyDown(e, i18n, setLoginError, user, loginError, setErrorMessage)}
                                value={user.password}
                                onChange={(e) => handleChange("password", e, setLoginError, setUser)}
                                type='password'/>
                        </Form.Field>
                        {showErrorMessage(errorMessage, i18n)}
                        <Button
                            type='submit'
                            className={"button-primary"}
                            onClick={() => {
                                login(setLoginError, user, loginError, i18n, setErrorMessage)
                            }}>
                            {i18n.t("login:user.login")}
                        </Button>
                        {/*<Button
                            className={"button-secondary"}
                            floated={"right"}
                            onClick={() => register(i18n.language)}>
                            {i18n.t("login:user.register")}
                        </Button>*/}
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default withTranslation(['login'])(LoginPage)